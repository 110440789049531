var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('div', {
    staticClass: "title-wrap v-application align-center"
  }, [_c('div', {
    staticClass: "mb-md-16 mb-7"
  }, [_c('h3', {
    staticClass: "title-18 font-weight-regular color-primary"
  }, [_vm._v(_vm._s(_vm.code == "scale-up-qna" ? "ScaleUP" : "Super Rookie"))]), _c('h2', {
    staticClass: "title-52 font-weight-bold"
  }, [_vm._v("Q&A")])])]), _c('v-simple-table', {
    staticClass: "table-primary table-primary--board align-center"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("번호")]), _c('th', {
    staticStyle: {
      "width": "45%"
    }
  }, [_vm._v("제목")]), _c('th', [_vm._v("이름")]), _c('th', [_vm._v("작성일")]), _c('th', [_vm._v("조회")]), _c('th', [_vm._v("답변")])])]), _c('tbody', [_vm._l(_vm.notices, function (notice, index) {
    return _c('tr', {
      key: `notice-${index}`,
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.showBoard(notice);
        }
      }
    }, [_c('td', [_vm._v("공지")]), _c('td', [_c('div', {
      staticClass: "ellipsis"
    }, [_vm._v(_vm._s(notice.subject))])]), _c('td', [_vm._v(_vm._s(notice.writer.name))]), _c('td', [_vm._v(_vm._s(notice.createdAt.toDate()))]), _c('td', [_vm._v(_vm._s(notice.viewCount))]), _c('td', [_vm._v("-")])]);
  }), _vm._l(_vm.boards, function (board, index) {
    return _c('tr', {
      key: `board-${index}`,
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.showBoard(board);
        }
      }
    }, [_c('td', [_vm._v(_vm._s(_vm.summary.totalCount - _vm.skip - index))]), _c('td', [_c('div', {
      staticClass: "ellipsis"
    }, [_vm._v(_vm._s(board.subject))])]), _c('td', [_vm._v(_vm._s(board.writer.name))]), _c('td', [_vm._v(_vm._s(board.createdAt.toDate()))]), _c('td', [_vm._v(_vm._s(board.viewCount))]), _c('td', [_vm._v(_vm._s(board.reply ? "답변 완료" : " 답변 준비중"))])]);
  })], 2)]), _c('div', {
    staticClass: "board-buttons board-buttons--center"
  }, [_c('button', {
    staticClass: "button button--primary",
    on: {
      "click": function ($event) {
        return _vm.$router.push(`${_vm.$route.path}/write`);
      }
    }
  }, [_vm._v("글쓰기")])]), _c('div', {
    staticClass: "board-bottom"
  }, [_c('pagination-component', {
    attrs: {
      "count": _vm.count,
      "value": _vm.page
    },
    on: {
      "change": _vm.changePage
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }