<template>
    <v-container>
        <div class="title-wrap v-application align-center">
            <div class="mb-md-16 mb-7">
                <h3 class="title-18 font-weight-regular color-primary">{{ code == "scale-up-qna" ? "ScaleUP" : "Super Rookie" }}</h3>
                <h2 class="title-52 font-weight-bold">Q&amp;A</h2>
            </div>
        </div>
        <v-simple-table class="table-primary table-primary--board align-center">
            <thead>
                <tr>
                    <th>번호</th>
                    <th style="width:45%;">제목</th>
                    <th>이름</th>
                    <th>작성일</th>
                    <th>조회</th>
                    <th>답변</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(notice, index) in notices" :key="`notice-${index}`" @click="showBoard(notice)" style="cursor:pointer;">
                    <td>공지</td>
                    <td><div class="ellipsis">{{ notice.subject }}</div></td>
                    <td>{{ notice.writer.name }}</td>
                    <td>{{ notice.createdAt.toDate() }}</td>
                    <td>{{ notice.viewCount }}</td>
                    <td>-</td>
                </tr>
                <tr v-for="(board, index) in boards" :key="`board-${index}`" @click="showBoard(board)" style="cursor:pointer;">
                    <td>{{ summary.totalCount - skip - index }}</td>
                    <td><div class="ellipsis">{{ board.subject }}</div></td>
                    <td>{{ board.writer.name }}</td>
                    <td>{{ board.createdAt.toDate() }}</td>
                    <td>{{ board.viewCount }}</td>
                    <td>{{ board.reply ? "답변 완료" : " 답변 준비중" }}</td>
                </tr>
            </tbody>
        </v-simple-table>
        <div class="board-buttons board-buttons--center">
            <button @click="$router.push(`${$route.path}/write`)" class="button button--primary">글쓰기</button>
        </div>
        <div class="board-bottom">
            <pagination-component :count="count" :value="page" @change="changePage" />
        </div>
    </v-container>
</template>

<script>
import paginationComponent from "@/components/client/control/pagination-component.vue";

export default {
    components: { paginationComponent },

    props: {
        code: { type: String, default: null },
        summary: {
            type: Object,
            default() {
                return { totalCount: 0 };
            },
        },
        boards: { type: Array, default: null },
        notices: { type: Array, default: null },
        page: { type: Number, default: 1 },
        skip: { type: Number, default: 0 },
        limit: { type: Number, default: null },
    },
    methods: {
        changePage(page) {
            this.$emit("page", page);
        },
        showBoard(board) {
            this.$router.push(`${this.$route.path}/${board._id}`);
        },
    },
    computed: {
        count() {
            return Math.ceil(this.summary.totalCount / this.limit);
        },
    },
};
</script>
